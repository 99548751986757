import {SiteBannerSection} from '../components/se-section/bannerHeader';
import {ConversionBySource, DrivingEngagement, EngagedSession, NewUser, RevenueBySource, Session, SourceMedium, TopPageUser, TotalUser, UserMedium, UserNewTrend, View, TotalUserTrend, KeyEvents} from '../components/ga-section/gaMatrix';
import {Keyword, AvgPosition, ClickOrgTrafic, Impression, HighestImpressionTable, HClickByWeek, HImpressionByWeek, DeviceCatgICCTR, GscRankChange} from '../components/gsc-section/gscMatrix';
import {AvgrPositionKrt, SearchVisibility, TopTenKeyword, TrackedKeyTable, TrackedKeyword} from '../components/krt-section/krtMatrix';
import {LocaSEO} from '../components/localSEO/components/localSeo';
import {CallClick, DeviceBreakdown, HistoricalTrend, LocalSeoCardDR, LocalSeoCardImpr, SearchBreakdown, WebClick} from '../components/gmb-section/gmbMatrix';
import {TabsRB} from '../components/tabs';
import {MatrixB, MatrixDR, MatrixK, MatrixPK, MatrixPT, MatrixT, MatrixTF, MatrixUR} from '../components/se-section/matrix';
import NotionIframe from '../components/notion';
import {AverageCpcFB, ClicksFB, ConversionFB, CostFB, FbAge, FbCampaignTable, FbGender, ImpressionsFB, VTConversionFB} from '../components/facebook-connect';
import {AllConversion, AllConversionValue, AllMatrix, AverageCpcGL, ByCampaign, Calls, ClicksGL, ConversionGL, ConversionRateGL, Conversions, ConversionsValue, CostGL, CostPerConvrsionGL, GoogleCampaignTable, ImpressionsGL, Missed, OverTime, Received, Unknown, VTConversionGL, VTConversionValGL} from '../components/google-ads';
import {GoogleAdsTab} from '../components/google-ads/tabs';
import {OttoSeo} from '../components/otto-section';
import {Summary} from '../components/summary';
import {Tooltip} from 'antd';

export const gmbAdd = (saveLayout, id, tag='gmb') => {
  layoutDataFullReport.forEach(items=> {
    if (items.tag == tag) {
      const maxValue = Math.max.apply(null, saveLayout.map(function(o) {
        return o.y;
      }));
      const y = saveLayout.find((a, index, arr)=> a.name == 'Map' && arr[index+1]);
      saveLayout.push({...items, w: items.w, h: items.h, x: items.x, y: y && tag == 'seo' ? y.y+1 : maxValue+1, i: `${saveLayout.length+1}`, minW: items.minW, minH: items.minH, tag: items?.tag, name: items.name, index: id});
    }
  });
  return saveLayout.map((item, index)=> ({...item, i: `${index}`}));
};

export const layoutDataFullReport = [
  {tag: 'se', name: 'Site Preview', x: 0, y: 0, w: 16, h: 2, i: '0', minW: 12, maxH: 3, minH: 2, component: SiteBannerSection, value: ''},
  {tag: 'se', name: 'Domain Rating', x: 0, y: 1, w: 2, h: 2, minW: 2, minH: 2, i: '1', component: MatrixDR, value: ''},
  {tag: 'se', name: 'URL Rating', x: 2, y: 1, w: 2, h: 2, minW: 2, minH: 2, i: '2', component: MatrixUR, value: ''},
  {tag: 'se', name: 'Backlinks', x: 4, y: 1, w: 2, h: 2, minW: 2, minH: 2, i: '3', component: MatrixB, value: ''},
  {tag: 'se', name: 'Trust Flow', x: 6, y: 1, w: 2, h: 2, minW: 2, minH: 2, i: '4', component: MatrixTF, value: ''},
  {tag: 'se', name: 'Keyword', x: 8, y: 1, w: 2, h: 2, minW: 2, minH: 2, i: '5', component: MatrixK, value: ''},
  {tag: 'se', name: 'Traffic', x: 10, y: 1, w: 2, h: 2, minW: 2, minH: 2, i: '6', component: MatrixT, value: ''},
  {tag: 'se', name: 'Paid kwds.', x: 12, y: 1, w: 2, h: 2, minW: 2, minH: 2, i: '7', component: MatrixPK, value: ''},
  {tag: 'se', name: 'Paid Traffic', x: 14, y: 1, w: 2, h: 2, minW: 2, minH: 2, i: '8', component: MatrixPT, value: ''},
  {tag: 'summary', name: 'Ai Summary', x: 0, y: 4, w: 16, h: 6, minW: 16, maxW: 16, i: '10', component: Summary, value: ''},
  {tag: 'tabs', name: 'tabs', x: 0, y: 5, w: 16, h: 1, minW: 16, minH: 1, i: '9', component: TabsRB, value: ''},
  {tag: 'otto', name: 'Before/After', x: 0, y: 6, w: 16, h: 4, minW: 16, minH: 4, maxW: 16, maxH: 4, i: '10', component: OttoSeo, value: ''},
  {tag: 'ot', name: 'Orders & Tasks', x: 0, y: 7, w: 16, h: 9, minW: 16, minH: 1, i: '11', component: NotionIframe, value: ''},
  {tag: 'ga', name: 'GaTitle', x: 0, y: 8, w: 16, h: 1, minW: 6, minH: 1, i: '12', component: <h2 style={{fontSize: '18px', fontWeight: '500', padding: '10px', display: 'flex'}} id='gaSection'>Google Analytics<div className='summary' style={{background: 'rgb(249, 171, 0)'}}><img src='/img/sunIcon.svg' alt='' /></div></h2>, value: ''},

  {tag: 'ga', name: 'Views', x: 0, y: 9, w: 3, h: 2, minW: 3.5, minH: 2, i: '13', component: View, value: ''},
  {tag: 'ga', name: 'Sessions', x: 3, y: 9, w: 3, h: 2, minW: 3.5, minH: 2, i: '14', component: Session, value: ''},
  {tag: 'ga', name: 'Total Users', x: 6, y: 9, w: 3, h: 2, minW: 3.5, minH: 2, i: '15', component: TotalUser, value: ''},
  {tag: 'ga', name: 'New Users', x: 9, y: 9, w: 3, h: 2, minW: 3.5, minH: 2, i: '16', component: NewUser, value: ''},
  {tag: 'ga', name: 'Engaged Sessions', x: 12, y: 9, w: 3.5, h: 2, minW: 3, minH: 2, i: '17', component: EngagedSession, value: ''},

  {tag: 'ga', name: 'How are New Users Trending', x: 0, y: 10, w: 8, h: 7, minW: 8, minH: 6, i: '18', component: UserNewTrend, value: ''},
  {tag: 'ga', name: 'How are Total Users Trending', x: 8, y: 10, w: 8, h: 7, minW: 8, minH: 6, i: '19', component: TotalUserTrend, value: ''},
  {tag: 'ga', name: 'Which channels are driving engagement', x: 0, y: 11, w: 16, h: 7, minW: 8, minH: 7, i: '20', component: DrivingEngagement, value: ''},
  {tag: 'ga', name: 'Total Users by Source/Medium', x: 0, y: 12, w: 8, h: 7, minW: 8, minH: 6, i: '21', component: SourceMedium, value: ''},
  {tag: 'ga', name: 'Top User Mediums', x: 8, y: 12, w: 8, h: 7, minW: 8, minH: 6, i: '22', component: UserMedium, value: ''},
  {tag: 'ga', name: 'Revenue by Source', x: 0, y: 12, w: 8, h: 7, minW: 8, minH: 6, i: '23', component: RevenueBySource, value: ''},
  {tag: 'ga', name: 'Conversions by Source', x: 8, y: 12, w: 8, h: 7, minW: 8, minH: 6, i: '24', component: ConversionBySource, value: ''},
  {tag: 'ga', name: 'Top Pages and Total Users', x: 0, y: 13, w: 16, h: 9, minW: 8, minH: 7, i: '25', component: TopPageUser, value: ''},
  {tag: 'ga', name: 'Key Events', x: 0, y: 14, w: 16, h: 9, minW: 8, minH: 7, i: '26', component: KeyEvents, value: ''},
  {tag: 'gsc', name: 'SeTitle', x: 0, y: 15, w: 16, h: 1, minW: 4, minH: 1, i: '27', component: <h2 style={{fontSize: '18px', fontWeight: '500', padding: '10px', display: 'flex'}} id='gscSection'>Google Search Console <div className='summary' style={{background: '#03a9f4'}}><img src='/img/sunIcon.svg' alt='' /></div></h2>, value: ''},
  {tag: 'gsc', name: 'Keywords', x: 0, y: 16, w: 4, h: 1.7, minW: 4, minH: 2, i: '28', component: Keyword, value: ''},
  {tag: 'gsc', name: 'Average Positions', x: 4, y: 16, w: 4, h: 2, minW: 4, minH: 2, i: '29', component: AvgPosition, value: ''},
  {tag: 'gsc', name: 'Clicks (Organic Traffic)', x: 8, y: 16, w: 4, h: 2, minW: 4, minH: 2, i: '30', component: ClickOrgTrafic, value: ''},
  {tag: 'gsc', name: 'Impressions', x: 12, y: 16, w: 4, h: 2, minW: 4, minH: 2, i: '31', component: Impression, value: ''},
  {tag: 'gsc', name: 'Top Keywords with Highest Impressions', x: 0, y: 17, w: 16, h: 9, minW: 8, minH: 7, i: '32', component: HighestImpressionTable, value: ''},
  {tag: 'gsc', name: 'Highest Clicks by Days of the Week', x: 0, y: 18, w: 8, h: 6, minW: 8, minH: 6, i: '33', component: HClickByWeek, value: ''},
  {tag: 'gsc', name: 'Highest Impressions by Days of the Week', x: 8, y: 18, w: 8, h: 6, minW: 8, minH: 6, i: '34', component: HImpressionByWeek, value: ''},
  {tag: 'gsc', name: 'Device Category Impressions, Clicks and CTR', x: 0, y: 19, w: 16, h: 5, minW: 8, minH: 4, i: '35', component: DeviceCatgICCTR, value: ''},
  {tag: 'gsc', name: 'GSC Rank Change Chart', x: 0, y: 20, w: 16, h: 5, minW: 8, minH: 4, i: '36', component: GscRankChange, value: ''},
  {tag: 'krt', name: 'KrtTitle', x: 0, y: 21, w: 16, h: 1, minW: 4, minH: 1, i: '37', component: <h2 style={{fontSize: '18px', fontWeight: '500', padding: '10px', display: 'flex'}} id='krtSection'>Keyword Rank Tracking <div className='summary' style={{background: 'rgb(1, 145, 151)'}}><img src='/img/sunIcon.svg' alt='' /></div></h2>, value: ''},
  {tag: 'krt', name: 'Tracked Keywords', x: 0, y: 22, w: 4, h: 2, minW: 4, minH: 2, i: '38', component: TrackedKeyword, value: ''},
  {tag: 'krt', name: 'Search Visibility', x: 4, y: 22, w: 4, h: 2, minW: 4, minH: 2, i: '39', component: SearchVisibility, value: ''},
  {tag: 'krt', name: 'Average Position', x: 8, y: 22, w: 4, h: 2, minW: 4, minH: 2, i: '40', component: AvgrPositionKrt, value: ''},
  {tag: 'krt', name: 'Keywords in Top 10', x: 12, y: 22, w: 4, h: 2, minW: 4, minH: 2, i: '41', component: TopTenKeyword, value: ''},
  {tag: 'krt', name: 'Tracked Keywords Data', x: 0, y: 23, w: 16, h: 8, minW: 8, minH: 7.3, i: '42', component: TrackedKeyTable, value: ''},
  {tag: 'fb', name: 'fbTitle', x: 0, y: 24, w: 16, h: 1, minW: 6, minH: 1, i: '43', component: <h2 style={{fontSize: '18px', fontWeight: '500', padding: '10px', display: 'flex'}} id='fbSection'>Facebook Ads <div className='summary' style={{background: '#0f7ee5'}}><img src='/img/sunIcon.svg' alt='' /></div></h2>, value: ''},

  {tag: 'fb', name: 'Impressions FB', x: 0, y: 25, w: 5, h: 2, minW: 5, minH: 2, i: '44', component: ImpressionsFB, value: ''},
  {tag: 'fb', name: 'CPC FB', x: 5, y: 25, w: 5, h: 2, minW: 5, minH: 2, i: '45', component: ClicksFB, value: ''},
  {tag: 'fb', name: 'CTR FB', x: 10, y: 25, w: 5, h: 2, minW: 5, minH: 2, i: '46', component: AverageCpcFB, value: ''},
  {tag: 'fb', name: 'Clicks FB', x: 0, y: 26, w: 5, h: 2, minW: 5, minH: 2, i: '47', component: VTConversionFB, value: ''},
  {tag: 'fb', name: 'Frequency FB', x: 5, y: 26, w: 5, h: 2, minW: 5, minH: 2, i: '48', component: CostFB, value: ''},
  {tag: 'fb', name: 'Spend FB', x: 10, y: 26, w: 5, h: 2, minW: 5, minH: 2, i: '49', component: ConversionFB, value: ''},

  {tag: 'fb', name: 'Age FB', x: 0, y: 27, w: 8, h: 7, minW: 8, minH: 6, i: '50', component: FbAge, value: ''},
  {tag: 'fb', name: 'Gender FB', x: 8, y: 27, w: 8, h: 7, minW: 8, minH: 6, i: '51', component: FbGender, value: ''},
  {tag: 'fb', name: 'Campaigns FB', x: 0, y: 28, w: 16, h: 9, minW: 8, minH: 7, i: '52', component: FbCampaignTable, value: ''},
  {tag: 'gl', name: 'googleAdTitle', x: 0, y: 29, w: 16, h: 1, minW: 6, minH: 1, i: '53', component: <h2 style={{fontSize: '18px', fontWeight: '500', padding: '10px', display: 'flex'}} id='googleSection'>Google Ads <div className='summary' style={{background: '#0f7ee5'}}><img src='/img/sunIcon.svg' alt='' /></div></h2>, value: ''},
  {tag: 'gl', name: 'Tab GL', x: 0, y: 30, w: 16, h: 1, minW: 16, minH: 1, i: '54', component: GoogleAdsTab, value: ''},
  {tag: 'gl', name: 'Matrix GL', x: 0, y: 31, w: 16, h: 4, minW: 4, minH: 2, i: '55', component: AllMatrix, value: ''},
  {tag: 'gl', name: 'Over time GL', x: 0, y: 32, w: 16, h: 7, minW: 8, minH: 6, i: '56', component: OverTime, value: ''},
  {tag: 'gl', name: 'By Campaign GL', x: 8, y: 33, w: 16, h: 7, minW: 8, minH: 6, i: '57', component: ByCampaign, value: ''},
  {tag: 'gl', name: 'Google Ad Campaigns GL', x: 0, y: 34, w: 16, h: 9, minW: 8, minH: 7, i: '58', component: GoogleCampaignTable, value: ''},

  {tag: 'seo', name: 'SeoTitle', x: 0, y: 35, w: 16, h: 1, minW: 6, minH: 1, i: '59', component: <h2 style={{fontSize: '18px', fontWeight: '500', padding: '10px', display: 'flex'}} id='lshSection'>Local SEO <div className='summary'><img src='/img/sunIcon.svg' alt='' /></div></h2>, value: ''},
  {tag: 'seo', name: 'Map', x: 0, y: 36, w: 16, h: 8, minW: 8, seoData: [], i: '60', component: LocaSEO, value: ''},
  {tag: 'gmb', name: 'gmbTitle', x: 0, y: 37, w: 16, h: 1, minW: 6, minH: 1, i: '61', component: <h2 style={{fontSize: '18px', fontWeight: '500', padding: '10px', display: 'flex'}} id='gmbSection'>Google Business Profile <div className='summary'><img src='/img/sunIcon.svg' alt='' /></div></h2>, value: ''},
  {tag: 'gmb', name: 'Seo Impressions', x: 0, y: 38, w: 4, h: 2, minW: 4, minH: 2, i: '62', component: LocalSeoCardImpr, value: ''},
  {tag: 'gmb', name: 'Website Clicks', x: 4, y: 38, w: 4, h: 2, minW: 4, minH: 2, i: '63', component: WebClick, value: ''},
  {tag: 'gmb', name: 'Call Clicks', x: 8, y: 38, w: 4, h: 2, minW: 4, minH: 2, i: '64', component: CallClick, value: ''},
  {tag: 'gmb', name: 'Direction Requests', x: 12, y: 38, w: 4, h: 2, minW: 4, minH: 2, i: '65', component: LocalSeoCardDR, value: ''},
  {tag: 'gmb', name: 'Historical Trend', x: 0, y: 39, w: 16, h: 7, minW: 8, minH: 7, i: '66', component: HistoricalTrend, value: ''},
  {tag: 'gmb', name: 'Platform and device breakdown', x: 0, y: 40, w: 6, h: 7, minW: 6, minH: 7, i: '67', component: DeviceBreakdown, value: ''},
  {tag: 'gmb', name: 'Searches breakdown', x: 8, y: 40, w: 10, h: 9, minW: 8, minH: 9, i: '68', component: SearchBreakdown, value: ''},
];

export const conversionsList = [
  {tag: 'gl', name: 'All Conversions GL', x: 0, y: 30, w: 4, h: 2, minW: 4, minH: 2, i: '52', component: AllConversion, value: ''},
  {tag: 'gl', name: 'Conversions Value GL', x: 4, y: 30, w: 4, h: 2, minW: 4, minH: 2, i: '53', component: AllConversionValue, value: ''},
  {tag: 'gl', name: 'Conversions GL', x: 8, y: 30, w: 4, h: 2, minW: 4, minH: 2, i: '54', component: Conversions, value: ''},
  {tag: 'gl', name: 'Conversions Value GL', x: 12, y: 30, w: 4, h: 2, minW: 4, minH: 2, i: '55', component: ConversionsValue, value: ''},
  {tag: 'gl', name: 'View Through Conversions GL', x: 0, y: 31, w: 4, h: 2, minW: 4, minH: 2, i: '56', component: VTConversionValGL, value: ''},
];

export const callsList = [
  {tag: 'gl', name: 'Calls GL', x: 0, y: 30, w: 4, h: 2, minW: 4, minH: 2, i: '52', component: Calls, value: ''},
  {tag: 'gl', name: 'Missed GL', x: 4, y: 30, w: 4, h: 2, minW: 4, minH: 2, i: '53', component: Missed, value: ''},
  {tag: 'gl', name: 'Received GL', x: 8, y: 30, w: 4, h: 2, minW: 4, minH: 2, i: '54', component: Received, value: ''},
  {tag: 'gl', name: 'Unknown GL', x: 12, y: 30, w: 4, h: 2, minW: 4, minH: 2, i: '55', component: Unknown, value: ''},
];

export const googleMatrix = [
  {tag: 'gl', name: 'Impressions GL', x: 0, y: 30, w: 4, h: 2, minW: 4, minH: 2, i: '52', component: ImpressionsGL, value: ''},
  {tag: 'gl', name: 'Clicks GL', x: 0, y: 30, w: 16, h: 5, minW: 4, minH: 2, i: '52', component: ClicksGL, value: ''},
  {tag: 'gl', name: 'Average CPC GL', x: 8, y: 30, w: 4, h: 2, minW: 4, minH: 2, i: '54', component: AverageCpcGL, value: ''},
  {tag: 'gl', name: 'VT Conversion GL', x: 12, y: 30, w: 4, h: 2, minW: 4, minH: 2, i: '55', component: VTConversionGL, value: ''},
  {tag: 'gl', name: 'Cost GL', x: 0, y: 31, w: 4, h: 2, minW: 4, minH: 2, i: '56', component: CostGL, value: ''},
  {tag: 'gl', name: 'Conversion GL', x: 4, y: 31, w: 4, h: 2, minW: 4, minH: 2, i: '57', component: ConversionGL, value: ''},
  {tag: 'gl', name: 'Cost Per Conversion GL', x: 8, y: 31, w: 4, h: 2, minW: 4, minH: 2, i: '58', component: CostPerConvrsionGL, value: ''},
  {tag: 'gl', name: 'Conversion Rate GL', x: 12, y: 31, w: 4, h: 2, minW: 4, minH: 2, i: '59', component: ConversionRateGL, value: ''},
];

const GetContext = ({title, summary}:any) => {
  return <div style={{display: 'flex', alignItems: 'flex-start'}}>
    <img src='/img/sunIcon.svg' style={{marginTop: '6px', height: '18px'}} />
    <div style={{marginLeft: '10px'}}>
      <h2 style={{color: 'white', fontSize: '15px'}}>{title}</h2>
      <p>{summary}</p>
    </div>
  </div>;
};

export const GmbTitle = (props:any) => {
  return <h2 style={{fontSize: '18px', fontWeight: '500', padding: '10px', display: 'flex'}} id='gmbSection'>Google Business Profile (<span style={{fontSize: '14px'}}>{props.name}</span>) {props.summary && props.isLinkgraph ?
    <Tooltip color='#0f7ee5' placement='rightBottom' overlayInnerStyle={{width: '390px', padding: '20px', borderTopRightRadius: '50px', borderTopLeftRadius: '50px', borderBottomRightRadius: '50px'}}title={<GetContext summary={props.summary} title='Google Business Profile' />}><div className='summary' style={{background: '#0f7ee5'}}>
      <img src='/img/sunIcon.svg' alt='' /></div> </Tooltip> : ''}</h2>;
};
export const GaTitle = (props:any) => {
  return <h2 style={{fontSize: '18px', fontWeight: '500', padding: '10px', display: 'flex'}} id='gaSection'>Google Analytics {props.summary && props.isLinkgraph ?
    <Tooltip color='rgb(249, 171, 0)' placement='rightBottom' overlayInnerStyle={{width: '390px', padding: '20px', borderTopRightRadius: '50px', borderTopLeftRadius: '50px', borderBottomRightRadius: '50px'}}title={<GetContext summary={props.summary} title='Google Analytics' />}>
      <div className='summary' style={{background: 'rgb(249, 171, 0)'}}>
        <img src='/img/sunIcon.svg' alt='' /></div> </Tooltip> : ''}</h2>;
};
export const KrtTitle = (props:any) => {
  return <h2 style={{fontSize: '18px', fontWeight: '500', padding: '10px', display: 'flex'}} id='krtSection'>Keyword Rank Tracking {props.summary && props.isLinkgraph ?
    <Tooltip color='rgb(1, 145, 151)' placement='rightBottom' overlayInnerStyle={{width: '390px', padding: '20px', borderTopRightRadius: '50px', borderTopLeftRadius: '50px', borderBottomRightRadius: '50px'}}title={<GetContext summary={props.summary} title='Keyword Rank Tracking' />}>
      <div className='summary' style={{background: 'rgb(1, 145, 151)'}}>
        <img src='/img/sunIcon.svg' alt='' /></div> </Tooltip> : ''}</h2>;
};

export const GAddTitle = (props:any) => {
  return <h2 style={{fontSize: '18px', fontWeight: '500', padding: '10px', display: 'flex'}} id='googleSection'>Google Ads {props.summary && props.isLinkgraph ?
    <Tooltip color='#0f7ee5' placement='rightBottom' overlayInnerStyle={{width: '390px', padding: '20px', borderTopRightRadius: '50px', borderTopLeftRadius: '50px', borderBottomRightRadius: '50px'}}title={<GetContext summary={props.summary} title='Google Ads' />}><div className='summary' style={{background: '#0f7ee5'}}>
      <img src='/img/sunIcon.svg' alt='' /></div> </Tooltip> : ''}</h2>;
};

export const GscTitle = (props:any) => {
  return <h2 style={{fontSize: '18px', fontWeight: '500', padding: '10px', display: 'flex'}} id='gscSection'>Google Search Console {props.summary && props.isLinkgraph ?
    <Tooltip color='#0f7ee5' placement='rightBottom' overlayInnerStyle={{width: '390px', padding: '20px', borderTopRightRadius: '50px', borderTopLeftRadius: '50px', borderBottomRightRadius: '50px'}}title={<GetContext summary={props.summary} title='Google Search Console' />}><div className='summary' style={{background: '#0f7ee5'}}>
      <img src='/img/sunIcon.svg' alt='' /></div> </Tooltip> : ''}</h2>;
};

export const FbAddTitle = (props:any) => {
  return <h2 style={{fontSize: '18px', fontWeight: '500', padding: '10px', display: 'flex'}} id='fbSection'>Facebook Ads {props.summary && props.isLinkgraph ?
    <Tooltip color='#0f7ee5' placement='rightBottom' overlayInnerStyle={{width: '390px', padding: '20px', borderTopRightRadius: '50px', borderTopLeftRadius: '50px', borderBottomRightRadius: '50px'}}title={<GetContext summary={props.summary} title='Facebook Ads' />}><div className='summary' style={{background: '#0f7ee5'}}>
      <img src='/img/sunIcon.svg' alt='' /></div> </Tooltip> : ''}</h2>;
};

export const SeoTitle = (props:any) => {
  return <h2 style={{fontSize: '18px', fontWeight: '500', padding: '10px', display: 'flex'}} id='lshSection'>Local SEO (<span style={{fontSize: '14px'}}>{props.name}</span>) {props.summary && props.isLinkgraph ?
    <Tooltip color='rgb(249, 171, 0)' placement='rightBottom' overlayInnerStyle={{width: '390px', padding: '20px', borderTopRightRadius: '50px', borderTopLeftRadius: '50px', borderBottomRightRadius: '50px'}}title={<GetContext summary={props.summary} title='Local SEO' />}><div className='summary' style={{background: 'rgb(249, 171, 0)'}}>
      <img src='/img/sunIcon.svg' alt='' /></div> </Tooltip> : ''}</h2>;
};


