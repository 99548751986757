import {useStore} from '@/store/root-store';
import {LoadingOutlined} from '@ant-design/icons';
import {observer} from 'mobx-react';
import styled from 'styled-components';

export const Summary = observer(()=> {
  const {reportBuilder: {details: {
    isConfig,
    singleProjectData,
    loadingAiSummary,
  }}} = useStore('');

  return <WrapperMain style={{height: '100%', width: '100%', marginTop: isConfig ? '' : '-20px'}}>
    <Wrapper isConfig={isConfig} loadingAiSummary={loadingAiSummary}>
      {loadingAiSummary ? <LoadingOutlined style={{color: 'white', fontSize: '25px', marginTop: '-20px'}} spin/> : <><img src='/img/summaryStar.svg' alt='' />
        <div style={{marginLeft: '20px', marginTop: '-8px'}} id='ottoSummary'>
          <h1>AI Summary Overview</h1>
          {singleProjectData?.aiSummary['reportSummary']?.length ? <div dangerouslySetInnerHTML={{__html: singleProjectData?.aiSummary['reportSummary']}}></div> : <i>No AI summary is available for this project.</i>}
        </div>
      </>}
    </Wrapper>
  </WrapperMain>;
});

const Wrapper = styled.div<{isConfig?: boolean; loadingAiSummary?: any}>`
    position: absolute;
    color: white;
    top: 0;
    display: flex;
    padding: 20px;
    align-items: flex-start;
    div{
    h1{margin: 0; color: white; font-size: 24px;};
    p{margin: 0;
    span{font-weight: 700;
    text-decoration: underline;}
    };
    }
`;

const WrapperMain = styled.div`
background-image: url(/img/summaryotto.png);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 8px
`;

