import React from 'react';
import {observer} from 'mobx-react-lite';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowUpRightFromSquare} from '@fortawesome/pro-regular-svg-icons';
import styled from 'styled-components';
import {useStore} from '@/store/root-store';
import {addProtocolToDomainHttps} from '@/utils/url';
import Head from 'next/head';
import {getLocation} from '@/utils/string';
import {Tooltip} from 'antd';


export const SiteBannerSection = observer(() => {
  const {
    competitorResearcherV2: {
      matrixSiteDatas,
    },
  } = useStore('');
  const {reportBuilder: {details: {singleProjectData}}} = useStore('');

  const getFormattedString = str => {
    const apiString = str || '';
    const step1 = apiString.replace(/\\r/g, '\r').replace(/\\n/g, '\n').replace(/\\t/g, '\t');
    const step2 = step1.trim();
    const step3 = step2.replace(/\s+/g, ' ');

    return step3;
  };

  const GetContext = ({summary}:any) => {
    return <div style={{display: 'flex', alignItems: 'flex-start'}}>
      <img src='/img/sunIcon.svg' style={{marginTop: '6px', height: '18px'}} />
      <div style={{marginLeft: '10px'}}>
        <h2 style={{color: 'white', fontSize: '15px'}}>Site Explorer</h2>
        <p>{summary}</p>
      </div>
    </div>;
  };

  return (<>
    <BannerWrapper className='dragDropStyle'>
      <Head>
        {matrixSiteDatas && <title>{getFormattedString(matrixSiteDatas['title'])} SEO Report | {getLocation(location)}</title>}
      </Head>
      <img src={matrixSiteDatas?.screenshotUrl} alt='' />
      <div>
        <span style={{display: 'flex', alignItems: 'center'}}>
          {matrixSiteDatas?.redirectTo || matrixSiteDatas?.url ? <a href={matrixSiteDatas?.redirectTo || addProtocolToDomainHttps(matrixSiteDatas?.url)} target='_blank' rel='noreferrer'>{matrixSiteDatas?.redirectTo || addProtocolToDomainHttps(matrixSiteDatas?.url)} <FontAwesomeIcon icon={faArrowUpRightFromSquare} /></a> : null}
          {singleProjectData?.aiSummary['seDataSummary'] ? <h2 style={{fontSize: '18px', fontWeight: '500', padding: '10px', display: 'flex'}}>
            <Tooltip
              color='#0f7ee5'
              placement='rightTop'
              overlayInnerStyle={{width: '390px', padding: '20px', borderTopRightRadius: '50px', borderBottomLeftRadius: '50px', borderBottomRightRadius: '50px'}}
              title={<GetContext summary={singleProjectData ? singleProjectData?.aiSummary['seDataSummary'] : ''} />}><div className='summary' style={{background: '#0f7ee5', marginLeft: '10px'}}>
                <img src='/img/sunIcon.svg' alt='' style={{height: '12px'}} /></div> </Tooltip>
          </h2> : '' }
        </span>
        <h2>{matrixSiteDatas && matrixSiteDatas['title'] ? getFormattedString(matrixSiteDatas['title']) : '-'}</h2>
        <p>{ matrixSiteDatas && matrixSiteDatas['metaDesc']}</p>
      </div>
    </BannerWrapper>
  </>
  );
});


const BannerWrapper = styled.div`
display: flex;
div{
  margin-left: 20px;
}
img{
  height: 100px;
  width: 165px;
  border-radius: 5px;
}
h2{
  color: white;
  margin: 0px;
  font-size: 24px;
  font-weight: 600;
  color: black;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
p{
  color: white;
  font-size: 14px;
  color: #4E5156;
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
`;
